import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCamperProgramProfile() {
  // Use toast
  const toast = useToast()

  const fetchLetterTemplates = () => axios
    .get('/auth/letter-templates')
    .then(response => response.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching letters',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const generateUserLetter = ({ userId, letter_template_id, program_id }) => axios
    .get(`/auth/users/${userId}/generate-letter`, {
      params: { letter_template_id, program_id },
      responseType: 'blob',
    })
    .then(response => response)
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error generating letter',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error.response
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchLetterTemplates,
    generateUserLetter,
  }
}
