<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching staff data
      </h4>
      <div class="alert-body">
        No user found with this staff id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'admin-staff'}"
        >
          Staff List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row
        class="d-flex align-items-stretch"
      >
        <b-col
          cols="12"
          md="7"
        >
          <user-view-user-info-card
            :user-data="userData"
          />

          <task-list
            :user-data="userData"
            :application-id="applicationID"
          />
        </b-col>

        <b-col
          cols="12"
          md="5"
        >
          <generate-user-letter
            :user-data="userData.user"
            :program-id="programID"
            user-type="Staff"
          />

          <user-documents
            :user-data="userData.user"
            :application-id="applicationID"
          />

          <b-card>
            <b-card-header class="mb-2 p-1">
              <b-card-title class="font-medium-2">
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="text-danger"
                />
                <span class="align-middle ml-50">{{ sessionSemester(2) }}</span>
              </b-card-title>
            </b-card-header>

            <b-card-text>
              <ul
                v-for="index in 4"
                :key="index"
                class="list-style"
              >
                <li
                  class="mb-1"
                >{{ sessionSemester(1) }} {{ index }}</li>
              </ul>
            </b-card-text>
          </b-card>

          <b-card>
            <b-card-header class="mb-2 p-1">
              <b-card-title class="font-medium-2">
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="text-danger"
                />
                <span class="align-middle ml-50">Groups</span>
              </b-card-title>
            </b-card-header>

            <b-card-text>
              <ul
                v-for="(group, index) in userData.groups"
                :key="index"
                class="list-style"
              >
                <li
                  class="mb-1"
                >{{ group.name }}</li>
              </ul>
            </b-card-text>
          </b-card>

        </b-col>
      </b-row>

    </template>

  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BCard, BCardHeader, BCardText, BCardTitle,
} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import UserViewUserInfoCard from '@/views/admin/staff/staff-program-profile/StaffViewUserInfoCard.vue'
import userStoreModule from '@/views/admin/staff/staffStoreModule'
import useStaffProgramProfile from '@/views/admin/staff/staff-program-profile/useStaffProgramProfile'

import TaskList from '@/views/admin/staff/staff-tasks/TaskList.vue'
import GenerateUserLetter from '@/views/components/generate-user-letter/GenerateUserLetter.vue'
import UserDocuments from '@/views/components/user-documents/UserDocuments.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BCardHeader,
    BCardText,
    BCardTitle,

    // Local Components
    UserViewUserInfoCard,
    TaskList,
    GenerateUserLetter,
    UserDocuments,
  },
  data() {
    return {
      applicationID: null,
      programID: null,
      userID: null,
      allergicProducts: [],
      isLoading: false,
    }
  },
  setup() {
    const userData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-staff'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-staff/fetchStaffProfile', { id: router.currentRoute.params.id })
      .then(response => { userData.value = response.data.data })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    const {
      fetchApplication,
    } = useStaffProgramProfile()

    return {
      userData,
      fetchApplication,
    }
  },
  async created() {
    this.programID = router.currentRoute.params.program
    this.applicationID = router.currentRoute.params.application
    this.userID = router.currentRoute.params.id
  },
}
</script>

<style>

</style>
