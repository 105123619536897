import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useStaffProgramProfile() {
  // Use toast
  const toast = useToast()

  const fetchApplication = ID => axios
    .get(`/auth/applications/${ID}`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching application item',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchAllergiesList = ID => axios
    .get(`/auth/allergy-products?userId=${ID}`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching allergies list',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchApplication,
    fetchAllergiesList,
  }
}
