<template>
  <b-card>
    <template #header>
      <h4
        class="d-flex align-items-center mb-0"
      >
        <feather-icon
          icon="MailIcon"
          size="18"
          class="text-danger"
        />
        <span class="align-middle ml-50">Letter Template</span>
      </h4>
    </template>
    <b-card>
      <b-card-text>
        <v-select
          v-model="letter"
          label="name"
          :placeholder="'Select letter'"
          :clearable="false"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="letterTemplatesOptions"
        />
      </b-card-text>

      <b-button
        v-if="letter"
        class="w-100"
        variant="primary"
        :disabled="isLoading"
        @click="generateLetter"
      >
        <b-spinner
          v-if="isLoading"
          label="Spinning"
          small
        />
        <span v-else>Generate Letter</span>
      </b-button>
    </b-card>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BButton, BSpinner,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { downloadExportFile } from '@core/mixins/downloadExportFile'

import moment from 'moment'
import useGenerateUserLetter from '@/views/components/generate-user-letter/useGenerateUserLetter'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BSpinner,

    vSelect,
  },
  mixins: [downloadExportFile],
  props: {
    userData: {
      type: Object,
      required: true,
    },
    programId: {
      type: String,
      required: true,
    },
    userType: {
      type: String,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isLoading: false,
      letter: null,
      letterTemplatesOptions: [],
    }
  },
  async created() {
    await this.fetchLetterTemplates().then(response => {
      this.letterTemplatesOptions = response.data
    })
  },
  setup() {
    const {
      fetchLetterTemplates,
      generateUserLetter,
    } = useGenerateUserLetter()

    return {
      fetchLetterTemplates,
      generateUserLetter,
    }
  },
  methods: {
    async generateLetter() {
      const queryParams = {
        userId: this.userData.id,
        letter_template_id: this.letter.id,
        program_id: this.programId,
      }

      this.isLoading = true
      await this.generateUserLetter(queryParams).then(response => {
        this.isLoading = false
        if (response) {
          const filename = `${this.letter.name}-${this.userType}-${this.userData.full_name}-${moment().format('YYYY-MM-DD')}`
          this.downloadFile(response.data, filename.replace(/\s/g, '-'), 'pdf')
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style>

</style>
